<template>
  <main class="main" v-if="mapOnePage">
    <div class="container">
      <div class="main__inner">
        <BreadcrumbsComponent :title="map.title" v-if="map && map.title" />
        <section class="section">
          <div class="open">
            <div class="open__head">
              <div class="open__head-left">
                <div class="open__head-title" v-if="map && map.title">
                  {{ map.title }}
                </div>
                <div class="open__head-info">
                  <time v-if="map && map.date_publication" class="open__head-date"
                    >{{ map.date_publication | formatDate }}
                  </time>
                  <div v-if="map && map.category && map.category.title">{{ map.category.title }}</div>
                </div>
              </div>
              <div class="open__head-description" v-if="map && map.text">
                {{ map.text }}
              </div>
            </div>
          </div>
        </section>
        <div v-html="map.frame" v-if="map && map.frame" class="open__iframe"></div>
        <section class="section section-pb48">
          <MapSlider />
        </section>
      </div>
    </div>
  </main>
</template>

<script>
import BreadcrumbsComponent from "components/BreadcrumbsComponent.vue";
import MapSlider from "@/views/maps/components/MapSlider.vue";
export default {
  name: "OpenMap",

  components: {
    BreadcrumbsComponent,
    MapSlider,
  },

  computed: {
    map() {
      return this.$store.state.mapsOnePage?.map;
    },
    mapOnePage() {
      return this.$store.state.mapsOnePage;
    },
  },

  async asyncData({ store, route }) {
    await store.dispatch("GET_OPEN_MAP", route.params.id);
  },

  metaInfo() {
    return this.$seo(
      "common",
      "Официальный сайт администрации города Махачкалы",
      "",
      "",
      "Официальный сайт администрации города Махачкалы",
      "",
      ""
    );
  },
  beforeRouteLeave(to, from, next) {
    this.$store.state.mapsOnePage = {};
    next();
  },
};
</script>
<style lang="stylus">
@import "~@/styles/parts/maps/open.styl"
@import "~@/styles/parts/swiper.styl"
</style>
