<template>
  <div>
    <div v-if="slides && slides.length" class="slider">
      <swiper ref="bigSwiper" :options="bigSwiperOptions">
        <swiper-slide v-for="(item, index) in slides" :key="index">
          <div class="slide">
            <router-link
              :to="{ name: 'map_open', params: { id: item.id } }"
              class="item"
            >
              <div class="item-img" v-if="item.head_img && item.head_img.img && item.head_img.img.url">
                <div class="image__container">
                  <img :src="item.head_img | image($store.state.api)" :alt="item.head_img | image_alt" />
                </div>
              </div>
              <div v-else-if="item.frame" class="item-iframe" v-html="item.frame"></div>
              <div v-if="item.title" class="item-title">{{ item.title }}</div>
              <div v-if="item.text" class="item-description text__cut">{{ item.text }}</div>
              <div class="item-bottom">
                <div class="item-territory" v-if="item.category && item.category.title">{{ item.category.title }}</div>
                <time v-if="item.date_publication" class="item-date">{{ item.date_publication | formatDate }}</time>
              </div>
            </router-link>
          </div>
        </swiper-slide>
      </swiper>
      <div>
        <a @click.prevent="bigSwiper.slidePrev()" class="slider__controls__left">
          <ArrowLeft/>
        </a>
        <a @click.prevent="bigSwiper.slideNext()" class="slider__controls__right">
          <ArrowRight/>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import {Swiper, SwiperSlide, directive} from "vue-awesome-swiper";
import ArrowLeft from "@/components/svg/ArrowLeft.vue";
import ArrowRight from "@/components/svg/ArrowRight.vue";

export default {
  name: "MayorActivitySlider",
  components: {
    Swiper,
    SwiperSlide,
    ArrowLeft,
    ArrowRight,
  },
  directives: {
    swiper: directive,
  },
  data() {
    return {
      loading: false,
      bigSwiperOptions: {
        slidesPerView: 3,
        loop: false,
        clickable: true,
        pagination: {
          el: ".slider__controls__list",
          clickable: true,
        },
        speed: 800,
        autoplay: {
          delay: 4000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        preloadImages: false,
        lazy: {
          loadOnTransitionStart: true,
        },
      },
    };
  },
  computed: {
    slides() {
      return this.$store.state.mapsOnePage?.map_actual;
    },
    bigSwiper() {
      return this.$refs.bigSwiper.$swiper;
    },
  },
  mounted() {
    if (this.slides && this.slides.length >= 2) {
      this.bigSwiper.slideTo(0, 1000, false);
    }
  },
};
</script>

<style lang="stylus">
.slider {
  position relative
  padding 0 32px 32px 32px
  margin -8px;
  height 100%
  .swiper-slide {
    height auto;
  }
  .slide {
    height: 100%;
    margin 8px;
    padding-bottom 8px
  }

  .slider__controls {
    &__left
    &__right {

      width 40px
      height 40px
      position absolute
      top 50%
      //transform translateY(-50%)
      z-index 4
      display flex
      align-items center
      justify-content center
      border none

      svg path {
        fill var(--color_gray_dark)
      }

      //&:active {
      //transform scale(0.9)
      //}

      &:hover {
        background-color var(--main_color_d30)
      }
    }

    &__left {
      margin-left: 32px;
      left 0
      transform translate3d(-100%, -50%, 0)
    }

    &__right {
      margin-right: 32px;
      right 0
      transform translate3d(100%, -50%, 0)
    }
  }
}

.item {
  display flex
  flex-direction column
  padding 12px
  border: 1px solid var(--color_gray_divider);
  height 100%

  &-img {
    width auto;
    height: 270px;
    overflow hidden
    margin-bottom 12px;
    img {
      object-fit cover
      height 270px
    }
  }
  &-iframe {
    width 100%;
    height 270px;
    margin-bottom: 12px;
    overflow hidden
    iframe {
      width: 100% !important
      height 100% !important
    }
  }

  &-title{
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--color_dark)
    margin-bottom 8px

    &:hover {
      color: var(--color_light_blue)
    }
  }

  &-description{
    font-size: 16px;
    line-height: 24px;
    color: var(--color_dark)
    margin-bottom auto
  }

  &-bottom{
    display flex
    justify-content space-between
  }

  &-territory{
    font-size: 14px;
    line-height: 20px;
    color: var(--color_gray_dark)
  }

  &-date{
    font-size: 14px;
    line-height: 20px;
    color: var(--color_gray_dark)
  }

  &-text{
    margin-top 20px
    font-size 24px
  }
}

</style>
